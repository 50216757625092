export const Basket = [
    {symbol: "BTC", amount: 16, ticker: 'bitcoin', min: 2000, max: 1000000},
    {symbol: "ETH", amount: 255, ticker: 'ethereum', min: 500, max: 20000},
    {symbol: "USDC", amount: 73100, ticker: 'usd-coin', value: 73100, price: 1},
    {symbol: "DOT", amount: 6000, ticker: 'polkadot', min: 1, max: 1000},
    {symbol: "BNB", amount: 232.6, ticker: 'binancecoin', min: 100, max: 5000},
    {symbol: "ETHW", amount: 300, ticker: 'ethereum-pow-iou', min: 1, max: 1000},
    {symbol: "SKEY", amount: 850000, ticker: 'skey-network', min: 0.01, max: 10},
    {symbol: "zkSNP", amount: 45000, ticker: 'synapse-network-2', min: 0.001, max: 10},
    {symbol: "YF-DAI", amount: 30, ticker: 'yfdai-finance', min: 10, max: 10000},
    {symbol: "KNG", amount: 2170, ticker: 'kanga-exchange', min: 0.1, max: 1000},
    {symbol: "JUP", amount: 16000, ticker: 'jupiter-exchange-solana', min: 0.01, max: 100},
    {symbol: "FOUND", amount: 2000000, ticker: 'ccfound-2', min: 0.01, max: 100},
    // {symbol: "SSGTx", amount: 125000, ticker: 'safeswap-token', min: 0.001, max: 1},
    // {symbol: "MallVR", amount: 42, ticker: null, min: 1, max: 1000},
    {symbol: "CAKE", amount: 5350, ticker: 'pancakeswap-token', min: 0.1, max: 1000},
    {symbol: "ARI", amount: 917000, ticker: 'ari', min: 0.001, max: 10},
    {symbol: "OSAK", amount: 12370000000, ticker: 'osaka-protocol', min: 0.001, max: 10}
]

export const BinanceTokens = [
    {symbol: 'BIO', amount: 542, ticker: 'BIOUSDT', price: 1},
    {symbol: 'ANIME', amount: 3390, ticker: 'ANIMEUSDT', price: 1},
    {symbol: 'SOLV', amount: 5817, ticker: 'SOLVUSDT', price: 1},
    {symbol: 'BERA', amount: 68, ticker: 'BERAUSDT', price: 1},
    {symbol: 'LAYER', amount: 206, ticker: 'LAYERUSDT', price: 1},
]

export const XTokens = [
    {symbol: 'FEG', ticker: 'feg-token-2', amount: 3280000},
    {symbol: 'ARI', ticker: 'ari', amount: 100000},
    {symbol: 'OSAK', ticker: 'osaka-protocol', amount: 8120000000},
    {symbol: 'CAW', ticker: 'crow-with-knife', amount: 72200000000},
    {symbol: 'ARC', ticker: 'arc', amount: 11540},
    {symbol: 'DEFIT', ticker: 'defit', amount: 4873},
]

export const buyback = 2832075;
export const reward = 12961;
